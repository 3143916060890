import { useState, useEffect, useReducer } from "react";
import axios from "axios";

// functions
import { appdataReducer, initialAppdata } from "./functions/appdataReducer.js";
import { AppdataContext, AppdataDispatchContext } from "./functions/appdataContext.js";
import { handleAbortSwishPayment } from "./functions/swishCalls";
import { getDefaultAmount } from "./functions/getDefaultAmount.js";

// components
import Modal from "./components/Modal/Modal";
import Icon from "./components/Icon/Icon";
import Form from "./components/Form/Form";
import ThankYouScreen from "./components/ThankYouScreen/ThankYouScreen.js";
import SwishPoll from "./components/SwishPoll/SwishPoll";

// styles
import styles from "./App.module.scss";

function App() {
  const [appdata, dispatch] = useReducer(appdataReducer, initialAppdata);
  const { initialServerData, swish } = appdata;
  const [isModalActive, setIsModalActive] = useState(false);
  const [isPollModalActive, setIsPollModalActive] = useState(false);
  const status = swish.requestState;

  const handleClosePoll = () => {
    console.log('Abort swish polling');
    // If swish status is polling or requesting - abort this process
    if ( status !== "success-swish") {
      handleAbortSwishPayment(dispatch);
    }
    // Close Poll Modal
    setIsPollModalActive(false)
  }

  // Get initial data
  useEffect(() => {
    const reactAppData = window.ktk_donations || {};
    dispatch({
      type: "setBackendSetup",
      value: reactAppData,
    });
    const { ajax_url, nonce } = reactAppData;

    let form_data = new FormData();
    form_data.set("action", "get_swish_modal_data");
    form_data.set("nonce", nonce);

    axios
      .post(ajax_url, form_data)
      .then((res) => {
        //console.log(res);
        dispatch({
          type: "setFormData",
          value: {
            amount: getDefaultAmount(res.data.data.amounts),
            phone: "",
          },
        });
        dispatch({
          type: "setInitialServerData",
          value: res.data?.data,
        });
      })
      .catch((err) => {
        //console.log(err);
        dispatch({
          type: "setFormData",
          value: {
            amount: "1",
            phone: "",
          },
        });
        dispatch({
          type: "setInitialServerData",
          value: {
            amounts: [
              {
                "amount": "1",
                "is_default": true
              },
              {
                "amount": "175",
                "is_default": false
              }
            ]
          },
        });
      });
  }, []);

  if (!initialServerData) {
    return null; 
  }

  return (
    <AppdataContext.Provider value={appdata}>
      <AppdataDispatchContext.Provider value={dispatch}>
        <div className={styles["app"]}>
          <button 
            className={styles["swish-button"]} 
            onClick={() => setIsModalActive(true)}
          >
            Swisha gåva
          </button>
          
          <Modal 
            isActive={isModalActive}
            closeModal={() => {
              setIsModalActive(false)
              handleAbortSwishPayment(dispatch);
            }}
          >
            {status === "success-swish" ? (
              <ThankYouScreen />
            ) : (
              <Form setIsPollModalActive={setIsPollModalActive} />
            )}
            <div className={styles["modal-footer"]}>
              <Icon
                className={styles["modal-swish-logo"]}
                variant="swish-symbol-alt"
                size="x-large"
              />
              <Icon
                className={styles["modal-90-logo"]}
                variant="svensk-insamlingskontroll"
                size="x-large"
              />
            </div>
          </Modal>
          <Modal 
            className={styles["poll-modal"]}
            isActive={isPollModalActive}
            closeModal={handleClosePoll}
            showCloseButton={false}
            variant="white"
            hideOverlay
            small
          >
            <SwishPoll status={status} closeModal={handleClosePoll}/>
          </Modal>
        </div>
      </AppdataDispatchContext.Provider>
    </AppdataContext.Provider>
  );
}

export default App;
