import clsx from "clsx";

// styles
import styles from "./Icon.module.scss";

// components
import { CardSymbol } from "./components/CardSymbol";
import { Checkmark } from "./components/Checkmark";
import { CheckmarkAlt } from "./components/CheckmarkAlt";
import { ChevronDown } from "./components/ChevronDown";
import { Close } from "./components/Close";
import { InfoTriangle } from "./components/InfoTriangle";
import { Loader } from "./components/Loader";
import { SvenskInsamlingskontroll } from "./components/SvenskInsamlingskontroll";
import { SwishSymbol } from "./components/SwishSymbol";
import { SwishSymbolAlt } from "./components/SwishSymbolAlt";

const Icon = ({ variant = "loader", size, ...props }) => {
  return (
    <div
      className={clsx(
        styles["icon"],
        size && styles[size],
        props.className,
        variant === "loader" && styles["loader"]
      )}
    >
      {variant === "card-symbol" && <CardSymbol />}
      {variant === "checkmark" && <Checkmark />}
      {variant === "checkmarkAlt" && <CheckmarkAlt />}
      {variant === "chevron-down" && <ChevronDown />}
      {variant === "close" && <Close />}
      {variant === "info-triangle" && <InfoTriangle />}
      {variant === "loader" && <Loader />}
      {variant === "svensk-insamlingskontroll" && <SvenskInsamlingskontroll />}
      {variant === "swish-symbol-alt" && <SwishSymbolAlt />}
      {variant === "swish-symbol" && <SwishSymbol />}
    </div>
  );
};

export default Icon;
