import axios from "axios";
import { useState, useContext, useRef } from "react";

// styles
import styles from "./Form.module.scss";

// functions
import { AppdataContext, AppdataDispatchContext } from "../../functions/appdataContext";
import { initiateSwishPayment } from "../../functions/swishCalls";
import { formatNumberString } from "../../functions/formatValue";
import { inputValidation } from "../../functions/inputValidation";
import { getErrorMessage } from "../../functions/getErrorMessage";

// components
import Button from "../Button/Button";
import InputField from "../InputField/InputField";
import Typography from "../Typography/Typography";
import RadioGroup from "../RadioGroup/RadioGroup";
import Radio from "../Radio/Radio";

const Form = ({ setIsPollModalActive }) => {
  const appdata = useContext(AppdataContext);
  const dispatch = useContext(AppdataDispatchContext);

  const stateRef = useRef();
  stateRef.current = appdata;

  const [customAmount, setCustomAmount] = useState("");

  const handleCustomAmount = (e) => {
    e.preventDefault();
    setCustomAmount(e.target.value);
    onChangeInput(e);
  };

  const { initialServerData, formData, formErrors } = appdata;
  const { amounts } = initialServerData || {};

  const canSubmit = 
    formData && 
    Object.values(formData).every((x) => x !== "" && x !== false) &&
    Object.values(formErrors).every((x) => x === "");

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      dispatch({
        type: "setFormValue",
        value: { name, value: formatNumberString(value) },
      });
    } else {
      dispatch({
        type: "setFormValue",
        value: { name, value },
      });
    }
  };

  const onChangeInputRadioOrCheckbox = (name, value) => {
    dispatch({
      type: "setFormValue",
      value: { name, value },
    });

    if (
      name === "amount" &&
      (value === amounts[0]?.amount ||
        value === amounts[1]?.amount )
    ) {
      setCustomAmount("");
    }
  };

  const clearFormError = (name) => {
    dispatch({
      type: "setFormErrorValue",
      value: { name, value: "" },
    });
  };

  const handleInputValidation = (name, value) => {
    const isValid = inputValidation({ name, value });

    if (isValid || !value) {
      clearFormError(name);
    } else {
      dispatch({
        type: "setFormErrorValue",
        value: { name, value: getErrorMessage(name) },
      });
    }
  };

  const radioVariant = "button-selector";

  // WP: Submit form
  const handleSubmit = (event) => {
    event.preventDefault();

    setIsPollModalActive(true);
    initiateSwishPayment(event.target, stateRef, dispatch);

    console.log("submit swish form");
  };

  return (
    <form onSubmit={handleSubmit} className={styles["form"]}>
      <Typography variant="h2">{initialServerData?.title ? initialServerData.title : "Swisha en gåva"}</Typography>
      {initialServerData?.text && <Typography>{initialServerData.text}</Typography>}

      <Typography variant="h3">{initialServerData?.title_selectAmount ? initialServerData.title_selectAmount : "1. Välj belopp"}</Typography>

      <RadioGroup
        onValueChange={(value) => onChangeInputRadioOrCheckbox("amount", value)}
        value={formData?.amount}
        variant={radioVariant}
      >
        {amounts?.map((item, index) => {
          const radioProps = {
            key: `amount-${index}-${item.amount}`,
            id: `amount-${index}-${item.amount}`,
            value: item.amount,
            label: `${item.amount} kr`,
            variant: radioVariant,
          };

          return <Radio {...radioProps} />;
          
        })}

        <Radio
          id={`amount-3-custom`}
          value={customAmount}
          label={
            initialServerData?.radio_customAmount
              ? initialServerData?.radio_customAmount
              : "Annat belopp"
          }
          variant={radioVariant}
        />
      </RadioGroup>
      
      {customAmount === formData?.amount && (
        <InputField
          type="number"
          name="amount"
          id="custom-amount"
          onChange={handleCustomAmount}
          value={formData?.amount}
          label={
            initialServerData?.input_customAmount?.label
              ? initialServerData?.input_customAmount?.label
              : "Belopp i kronor"
          }
          hint={initialServerData?.input_customAmount?.hint}
          min="50"
          onWheel={(e) => e.target.blur()}
          onBlur={(event) => handleInputValidation("customAmount", event.target.value)}
          error={formErrors?.customAmount}
          required
        />
      )}

      <Typography variant="h3">{initialServerData?.title_phone ? initialServerData.title_phone : "2. Ange telefonnummer"}</Typography>
      <InputField
        type="tel"
        name="phone"
        id="phone"
        onChange={onChangeInput}
        value={formData.phone}
      />

      <Button type="submit" disabled={!canSubmit}>
        {initialServerData?.button_submit ? initialServerData.button_submit : "Swisha en gåva"}
      </Button>
      {initialServerData?.text_belowSubmit && <Typography>{initialServerData.text_belowSubmit}</Typography>}
    </form>
  );
};
export default Form;
