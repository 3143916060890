import clsx from "clsx";

// components 
import Button from "../Button/Button";
import Typography from "../Typography/Typography";
import Icon from "../Icon/Icon";

// styles
import styles from "./SwishPoll.module.scss";

const SwishPoll = ({status, closeModal}) => {
  const showLoader = 
    status === "requesting-swish"
    || status === "polling-swish";

  const buttonVariant = status === "requesting-swish" || status === "polling-swish" ?  "secondary" : "primary";
  const buttonText = status === "requesting-swish" || status === "polling-swish" ?  "Avbryt" : "Stäng";
  
  return (
  <div
    className={clsx(
      styles["swish-poll"],
    )}
  >
    <Icon
      className={styles["swish-logo"]}
      variant="swish-symbol-alt"
    />

    {status === "requesting-swish" && <Typography variant="h3">Startar</Typography>}
    {status === "polling-swish" && <Typography variant="h3">Öppna din Swish-app för att slutföra donationen.</Typography>}
    {status === "success-swish" && <Typography variant="h3">Tack för din donation!</Typography>}
    {status === "declined-swish" && <Typography variant="h3">Betalning avbruten i swish-app.</Typography>}
    {status === "failed-swish" && <Typography variant="h3">Någonting gick fel.</Typography>}

    <div className={styles["icon-wrapper"]}>
      {status === "success-swish" && (
        <Icon
          className={styles["checkmark-icon"]}
          variant="checkmarkAlt"
        />
      )}

      {(status === "failed-swish" || status === "declined-swish") && (
        <Icon
          className={styles["error-icon"]}
          variant="info-triangle"
        />
      )}
      
      {showLoader && (
        <Icon
          className={styles["loader"]}
          variant="loader"
        />
      )}
    </div>
    <Button 
      className={styles["close-button"]} 
      variant={buttonVariant}
      onClick={closeModal}
    >
      {buttonText}
    </Button>
  </div>
)};

export default SwishPoll;
