import ReactDOM from "react-dom";
import clsx from "clsx";
import {
  Portal,
  Overlay,
  Content,
  Root,
  Trigger,
  Close,
} from "@radix-ui/react-dialog";


// styles
import styles from "./Modal.module.scss";

// components
import Icon from "../Icon/Icon";
import Button from "../Button/Button";

const Modal = ({ 
  className,
  children, 
  isActive, 
  closeModal,
  showCloseButton = true, 
  trigger,
  variant = 'pink',
  small,
}) => (
  <Root onOpenChange={closeModal} open={isActive}>
    {trigger && <Trigger asChild>{trigger}</Trigger>}

    <Portal>
      <Overlay className={clsx(styles["modal-overlay"])} />

      <Content 
        className={clsx(
          styles["swish-modal"],
          className && className,
          variant && styles[variant],
          small && styles["small"]
        )}
      >
        {showCloseButton && (
          <Close asChild>
            <Button className={styles["swish-modal-close"]} aria-label="Close" circular>
              <Icon variant="close" size="medium" />
            </Button>
          </Close>
        )}
        <div className={styles["swish-modal-inner"]}>
          {children}
        </div>
      </Content>
    </Portal>
  </Root>
);

export default Modal;
