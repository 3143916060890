import axios from "axios";

const POLLING_INTERVAL_DURATION = 1200; // 1.2 sek

const initiateSwishPayment = async (eventTarget, stateRef, dispatch) => {
  dispatch({
    type: "setSwishStatus",
    value: "requesting-swish",
  });

  const appdata = stateRef.current;
  const { formData } = appdata;
  const { ajax_url, nonce } = appdata?.backendSetup;

  let init_swish_data = new FormData(eventTarget);
  init_swish_data.set("action", "swish_modal_init_payment");
  init_swish_data.set("nonce", nonce);

  for ( var key in formData ) {
    init_swish_data.set(key, formData[key]);
  }

  //console.log(formData);

  try {
    const res = await axios.post(ajax_url, init_swish_data);
    //console.log(res);
    if (
      stateRef.current?.swish?.requestState !==
      "requesting-swish"
    ) {
      return;
    }
    if (res.data.id) {
      // Successfully intiated swish payment
      dispatch({
        type: "setSwishStatus",
        value: "polling-swish",
      });
      pollSwish(res.data.id, stateRef, dispatch);
    } else {
      // If Error
      console.error("Something went wrong. No reference token");
      dispatch({ type: "setSwishStatus", value: "failed-swish" });
    }
  } catch (e) {
    console.error(e);
    dispatch({ type: "setSwishStatus", value: "failed-swish" });
  }
};

const pollSwish = async (requestId, stateRef, dispatch) => {
  await new Promise(res => setTimeout(res, 0));

  // TODO - set up timout

  const appdata = stateRef.current;
  const { ajax_url, nonce } = appdata?.backendSetup;

  if (
    stateRef.current?.swish?.requestState !== "polling-swish"
  ) {
    return;
  }

  await new Promise((res) => setTimeout(res, POLLING_INTERVAL_DURATION));

  let poll_swish_data = new FormData();
  poll_swish_data.append(
    "action",
    "swish_modal_poll"
  );
  poll_swish_data.append("nonce", nonce);
  poll_swish_data.append("id", requestId);

  try {
    const res = await axios.post(ajax_url, poll_swish_data);
    //console.log(`Polling status: ${res}`);
    if (res.data) {
      const pollStatus = res.data?.status?.toLowerCase();
      //console.log(pollStatus);
      if (pollStatus === "started") {
        // Still waiting for signage
        Promise.resolve().then(() =>
          pollSwish(requestId, stateRef, dispatch)
        );
      } else if (pollStatus === "paid") {
        dispatch({ type: "setSwishStatus", value: "success-swish" });

      } else if (pollStatus === "declined") {
        console.error("payment declined");
        dispatch({ type: "setSwishStatus", value: "declined-swish" });

      } else if (pollStatus === "error") {
        console.error("failed to sign, show somehow");
        dispatch({ type: "setSwishStatus", value: "failed-swish" });

      } else {
        dispatch({ type: "setSwishStatus", value: "failed-swish" });
        //console.log("Unknown status", pollStatus);
      }
    } else {
      console.error("Something went wrong");
      dispatch({ type: "setSwishStatus", value: "failed-swish" });
    }
  } catch (err) {
    //console.log(err);
    Promise.resolve().then(() =>
      pollSwish(requestId, stateRef, dispatch)
    );
    //dispatch({ type: "setSwishStatus", value: "failed-swish" });
  }
};

const handleAbortSwishPayment = (dispatch) => {
  dispatch({
    type: "setSwishStatus",
    value: "idle",
  });
};

export {
  initiateSwishPayment,
  handleAbortSwishPayment,
};
