export const SwishSymbol = (className) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.26186 16.1045C11.2722 17.529 14.9873 16.824 17.2317 14.1781C19.892 11.042 19.4788 6.36667 16.3088 3.73548L14.2015 6.21981C16.678 8.27542 17.0008 11.928 14.9225 14.3781C13.2653 16.3317 10.5368 16.984 8.26186 16.1045Z"
      fill="url(#paint0_linear_210_1136)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.26186 16.1045C11.2722 17.529 14.9873 16.824 17.2317 14.1781C17.5066 13.8541 17.7486 13.5136 17.9582 13.1606C18.3126 10.9814 17.5331 8.67807 15.6996 7.15618C15.2338 6.7695 14.7291 6.4577 14.2015 6.21981C16.678 8.27542 17.0008 11.928 14.9225 14.3781C13.2653 16.3317 10.5368 16.984 8.26186 16.1045Z"
      fill="url(#paint1_linear_210_1136)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7234 2.72187C11.7131 1.29742 7.99798 2.00236 5.75354 4.64829C3.09327 7.78441 3.50646 12.4597 6.67643 15.0909L8.78379 12.6066C6.30725 10.551 5.98445 6.8984 8.06278 4.44829C9.71996 2.49468 12.4484 1.84239 14.7234 2.72187Z"
      fill="url(#paint2_linear_210_1136)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7234 2.72187C11.7131 1.29742 7.99798 2.00236 5.75354 4.64829C5.47865 4.97234 5.23669 5.31285 5.0271 5.6658C4.67264 7.84499 5.45212 10.1483 7.28566 11.6702C7.75151 12.0569 8.25614 12.3687 8.78379 12.6066C6.30725 10.551 5.98445 6.8984 8.06278 4.44829C9.71996 2.49468 12.4484 1.84239 14.7234 2.72187Z"
      fill="url(#paint3_linear_210_1136)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_210_1136"
        x1="16.1104"
        y1="13.9273"
        x2="11.0774"
        y2="4.65833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF2131" />
        <stop offset="1" stopColor="#FECF2C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_210_1136"
        x1="12.8141"
        y1="2"
        x2="1.50217"
        y2="18.0831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBC52C" />
        <stop offset="0.263921" stopColor="#F87130" />
        <stop offset="0.560797" stopColor="#EF52E2" />
        <stop offset="1" stopColor="#661EEC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_210_1136"
        x1="7.08233"
        y1="10.2035"
        x2="11.9114"
        y2="19.2832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#78F6D8" />
        <stop offset="0.265705" stopColor="#77D1F6" />
        <stop offset="0.554471" stopColor="#70A4F3" />
        <stop offset="1" stopColor="#661EEC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_210_1136"
        x1="9.09424"
        y1="12.6063"
        x2="14.5433"
        y2="3.03002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#536EED" />
        <stop offset="0.246658" stopColor="#54C3EC" />
        <stop offset="0.5642" stopColor="#64D769" />
        <stop offset="1" stopColor="#FECF2C" />
      </linearGradient>
    </defs>
  </svg>
);
