export const formatSsn = (value) => {
  const ssn = value?.replace(/[^\d]/g, "");
  const ssnLength = ssn.length;

  if (!value) return value;

  if (ssnLength <= 8) return ssn;

  return `${ssn.slice(0, 8)}-${ssn.slice(8)}`;
};

export const formatNumberString = (value) => {
  const ssn = value?.replace(/[^\d]/g, "");

  if (!value) return value;

  return ssn;
};
