import clsx from "clsx";

// styles
import styles from "./InputField.module.scss";

// components
import Icon from "../Icon/Icon";
import Typography from "../Typography/Typography";

const InputField = ({
  type = "text",
  name,
  id,
  label,
  placeholder = "",
  hint,
  value,
  onChange,
  error,
  isTextArea = false,
  ...props
}) => {
  const Element = isTextArea ? "textarea" : "input";
  const hasSuffix = error || type === "date" || type === "number";

  return (
    <div className={clsx(styles["input-field"], props.className)}>
      <div className={styles["label-hint-wrapper"]}>
        {label && (
          <label htmlFor={id} className={styles["label"]}>
            {label}
          </label>
        )}
        {hint && (
          <Typography color="black-75">
            {hint}
          </Typography>
        )}
      </div>

      <Element
        className={clsx(
          styles["input"],
          error && styles["error"],
          Element === "textarea" && styles["textarea"]
        )}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />

      {hasSuffix && (
        <div
          className={clsx(
            styles["suffix-container"],
            error && styles["error"],
            type === "number" && styles["number-suffix"]
          )}
        >
          {error ? (
            <Icon variant="info-triangle" size="small" />
          ) : type === "date" ? (
            <Icon variant="chevron-down" size="medium" />
          ) : type === "number" ? (
            <Typography>kr</Typography>
          ) : (
            ""
          )}
        </div>
      )}

      {error && <div className={styles["error-message"]}>{error}</div>}
    </div>
  );
};

export default InputField;
