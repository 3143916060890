export const Checkmark = (className) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="18" height="18" rx="9" fill="#006CCF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7382 6.83463C14.1297 6.45519 14.7588 6.45519 15.1503 6.83463C15.5496 7.22172 15.5496 7.85531 15.1503 8.24239L9.03916 14.1655C8.64768 14.5449 8.0186 14.5449 7.62711 14.1655L4.84934 11.4732C4.44996 11.0861 4.44996 10.4525 4.84934 10.0654C5.24082 9.68596 5.8699 9.68596 6.26139 10.0654L8.33314 12.0734L13.7382 6.83463Z"
      fill="white"
    />
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="9"
      stroke="#006CCF"
      strokeWidth="2"
    />
  </svg>
);
