const appdataReducer = (appdata, action) => {
  switch (action.type) {
    case "update": {
      //console.log(action);
      return appdata;
    }
    case "setInitialServerData": {
      return { ...appdata, initialServerData: action.value };
    }
    case "setFormData": {
      return { ...appdata, formData: action.value };
    }
    case "setFormValue": {
      const { name, value } = action.value;
      const newFormData = {
        ...appdata.formData,
        [name]: value
      };

      return { ...appdata, formData: newFormData };
    }
    case "setFormErrorValue": {
      const { name, value } = action.value;
      return { ...appdata, formErrors: {...appdata.formErrors, [name]: value }};
    }
    case "setStatus": {
      return { ...appdata, status: action.value };
    }
    case "setBackendSetup": {
      return { ...appdata, backendSetup: action.value };
    }

    // Swish options
    case "setSwishStatus": {
      console.log(`Update swish status to: ${action.value}`);
      return { ...appdata, swish: { ...appdata.swish, requestState: action.value } };
    }
    
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

const initialAppdata = {
  backendSetup: {
    ajax_url: null,
    nonce: null,
    nonce_payment: null,
    post_id: null,
  },
  formData: {
  },
  formErrors: {
  },
  swish: {
    requestState: "idle",
  },
};

export { appdataReducer, initialAppdata };
