export const getErrorMessage = (name) => {
  const errors = {
    customAmount: "Belopp måste vara mer än 50",
  };

  if (errors[name]) {
    return errors[name];
  }
  
  return "Fältet är felaktigt";
};
