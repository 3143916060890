import clsx from "clsx";
import Typography from "../Typography/Typography";

// styles
import styles from "./ThankYouScreen.module.scss";

const ThankYouScreen = () => (
  <div className={styles["thankyou-screen"]} >
    <Typography variant="h2">Tack för din gåva!</Typography>
    <Typography>Med din gåva är du med och bidrar till arbetet för kvinnors rättigheter på några av världens tuffaste platser.</Typography>
  </div>
);

export default ThankYouScreen;