export const inputValidation = ({ name, value }) => {
 if (name === "customAmount") {
    if (value < 50) {
      return false;
    } else {
      return true;
    }
  } else {
    return;
  }
};
