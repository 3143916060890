import { Item, Indicator } from "@radix-ui/react-radio-group";
import clsx from "clsx";

// styles
import styles from "./Radio.module.scss";

// components
import Typography from "../Typography/Typography";
import Icon from "../Icon/Icon";

const Radio = ({
  className,
  id,
  label,
  variant = "default",
  imageProps,
  // imageProps = {
  //   height: 150,
  //   width: 150,
  //   src: "https://placekitten.com/150/150",
  //   alt: "Gullig katt",
  // },
  ...props
}) => {
  return (
    <Item
      className={clsx(styles["radio-button"], styles[variant], className)}
      {...props}
    >
      {variant === "image-selector" && (
        <div className={styles["thumbnail-wrapper"]}>
          <img {...imageProps} alt={imageProps?.alt} />
        </div>
      )}

      {variant === "image-selector" ? (
        <Indicator className={styles["indicator"]}>
          <Icon variant="checkmark" />
        </Indicator>
      ) : (
        <div className={styles["indicator-wrapper"]}>
          <Indicator className={styles["indicator"]} />
        </div>
      )}

      <label className={styles["label"]}>
        {label}
      </label>

      {Boolean(
        variant === "button-selector" && (id === "creditcard" || id === "swish")
      ) && (
        <Icon
          className={styles["icon"]}
          variant={id === "creditcard" ? "card-symbol" : "swish-symbol"}
          size="large"
        />
      )}
    </Item>
  );
};

export default Radio;
