import { Root } from "@radix-ui/react-radio-group";
import clsx from "clsx";

// styles
import styles from "./RadioGroup.module.scss";

// components
import Typography from "../Typography/Typography";

const RadioGroup = ({
  className,
  children,
  variant = "default",
  label,
  ...props
}) => (
  <div>
    {label && <Typography variant="label">{label}</Typography>}
    <Root
      className={clsx(styles["radio-group-swish"], styles[variant], className)}
      {...props}
    >
      {children}
    </Root>
  </div>
);

export default RadioGroup;
