import clsx from "clsx";
import Typography from "../Typography/Typography";

// styles
import styles from "./Button.module.scss";

const Button = ({
  className,
  type = "button",
  onClick,
  children,
  disabled,
  variant = "primary",
  circular = false,
}) => (
  <button
    type={type}
    className={clsx(
      styles["button"],
      className,
      circular ? styles["circular"] : styles["full-width"],
      styles[variant],
      disabled && styles["disabled"]
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
